/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@mui/material/Tooltip';
import { Button, CircularProgress, Box } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  Drawer,
  Divider,
  Avatar,
  List,
  ListSubheader,
  Typography,
  Hidden,
  IconButton,
  Badge,
  Link,
  colors
} from '@material-ui/core';
import cookies from 'js-cookie'
import i18next from 'i18next';

import { useTranslation } from "react-i18next";

import MoreIcon from '@material-ui/icons/MoreVert';
import NavItem from 'src/components/NavItem';
import navConfig from './navConfig';
import typography from 'src/theme/typography';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  onlineBadge: {
    backgroundColor: colors.green[600]
  },
  awayBadge: {
    backgroundColor: colors.orange[600]
  },
  busyBadge: {
    backgroundColor: colors.red[600]
  },
  offlineBadge: {
    backgroundColor: colors.grey[300]
  },
  avatar: {
    cursor: 'pointer',
    width: 32,
    height: 32
  },
  details: {
    marginLeft: theme.spacing(2),
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200]
  },
  centerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    marginBottom: 30,
    marginTop: 10
  },
  flag:{
    maxWidth: 30,
    maxHeight: 30,
  },
  menu:{
    width: 160,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  text:{
    marginRight: 30,
    marginLeft: 30
  }
}));

function renderNavItems({ items, subheader, key, ...rest }) {
  return (
    <List key={key}>
      {subheader && <ListSubheader disableSticky>{subheader}</ListSubheader>}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) => ReduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function ReduceChildRoutes({ acc, pathname, item, depth = 0 }) {
  const { t } = useTranslation();

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      
      <NavItem 
        depth={depth}
        // icon={item.icon}
        key={item.href}
        label={item.label}
        open={Boolean(open)}
        title={t(item.title)}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.href}
        label={item.label}
        title={t(item.title)}
      />
    );
  }

  return acc;
}

function NavBar({ openMobile, onMobileClose, className, ...rest }) {

  const { t } = useTranslation();

  const classes = useStyles();
  const location = useLocation();
  const session = useSelector(state => state.session);
  const [status, setStatus] = useState('online');

  const handleStatusToggle = () => {
    const statusSeq = {
      online: 'away',
      away: 'busy',
      busy: 'offline',
      offline: 'online'
    };

    setStatus(prevStatus => statusSeq[prevStatus]);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    

    // eslint-disable-next-line
  }, [location.pathname]);


  const content = (
    
    <div {...rest} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {navConfig.map(list =>
          renderNavItems({
            items: list.items,
            
          })
        )
        }
      </nav>
      {/* <Divider className={classes.divider} /> */}
      {/* <div className={classes.profile}>
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          classes={{
            dot: classes.badgeDot,
            badge: clsx({
              [classes.badge]: true,
              [classes.onlineBadge]: status === 'online',
              [classes.awayBadge]: status === 'away',
              [classes.busyBadge]: status === 'busy',
              [classes.offlineBadge]: status === 'offline'
            })
          }}
          variant="dot"
        >
          <Avatar
            alt="Person"
            onClick={handleStatusToggle}
            className={classes.avatar}
            src={session.user.avatar}
          />
        </Badge>
        <div className={classes.details}>
          <Link
            component={RouterLink}
            to="/profile/1/timeline"
            variant="h5"
            color="textPrimary"
            underline="none"
          >
            {`${session.user.first_name} ${session.user.last_name}`}
          </Link>
          <Typography variant="body2">{session.user.bio}</Typography>
        </div>
        <IconButton
          className={classes.moreButton}
          size="small"
        >
          <MoreIcon />
        </IconButton>
      </div>
    </div> */}
    </div>
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);

    let name = e.target.innerText
    console.log(name) // => This logs menu item text.

    if (name === 'English') {
      i18next.changeLanguage('en')
      window.location.reload(false);

      //setIsRtl(false)
    } else {
      i18next.changeLanguage('ar')
      window.location.reload(false);

      //setIsRtl(true)
    }
  };
  const language = [
    {
      code: 'ar',
      name: 'العربية',
      country_flag: '/images/flags/saudi-arabia_2.png',
      dir: 'rtl'
    },
    {
      code: 'en',
      name: 'English',
      country_flag: '/images/flags/united-kingdom.png',
      dir: 'ltr'
    }
  ]

  const currentCountryCode = cookies.get('i18next') || 'ar'
  const currentLanguage = language.find(l => l.code === currentCountryCode)
  console.log(currentLanguage)

  useEffect(() => {
    document.body.dir =  currentLanguage.dir 
  }, [currentLanguage])
  

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor={document.body.dir}
          classes={{
            paper: classes.mobileDrawer
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
          <Divider />
          <Box className={classes.centerBox}>
        <Tooltip title={t('common.select_language')}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar className={classes.avatar} sx={{ width: 28, height: 28 }} src="/images/flags/internet.png"></Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            //overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 42,
              height: 42,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {language.map((option) => (
          <MenuItem className={classes.menu} key={option.code} value={option.code}>
            <Avatar className={classes.flag}
              src={option.country_flag}
            />
            <Typography className={classes.text}>{option.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor={document.body.dir}
          classes={{
            paper: classes.desktopDrawer
          }}
          open
          variant="persistent"
        >
          {content}
          <Divider />
          <Box className={classes.centerBox}>
        <Tooltip title={t('common.select_language')}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar className={classes.avatar} sx={{ width: 28, height: 28 }} src="/images/flags/internet.png"></Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            //overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 42,
              height: 42,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {language.map((option) => (
          <MenuItem className={classes.menu} key={option.code} value={option.code}>
            <Avatar className={classes.flag}
              src={option.country_flag}
            />
            <Typography className={classes.text}>{option.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
