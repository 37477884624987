/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { colors } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewConfigIcon from '@material-ui/icons/ViewComfy';
import ListIcon from '@material-ui/icons/List';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import Label from 'src/components/Label';

export default [
  
  {
    subheader: 'Pages',
    items: [
      {
        title: "nav_bar.overview",
        href: '/overview',
        icon: HomeIcon,
      },
      {
        title: 'nav_bar.employees',
        href: '/employees',
        icon: PeopleIcon
      },
      {
        title: 'nav_bar.working_hours',
        href: '/working-hours',
        icon: AccessTimeIcon
      },
      {
        title: 'nav_bar.remote_work',
        href: '/remote-work',
        icon: HomeWorkIcon
      },
      {
        title: 'nav_bar.locations',
        href: '/locations',
        icon: LocationOnIcon
      },
      {
        title: 'nav_bar.attendance_records',
        href: '/attendance-records',
        icon: CalendarTodayIcon
      }
    ]
  },
];
