import React from "react";
import { Detector } from "react-detect-offline";
import { makeStyles } from '@material-ui/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
   root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
   },
    image: {
        maxWidth: "60%",
        height: 'auto',
    },
    typo:{
        fontFamily: 'Cairo !important',
    }
   
}));

const CheckConnection = props => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Detector
                render={({ online }) => (
                    online ? props.children :
                        <div className={classes.root}>
                            <img
                                alt="Under development"
                                className={classes.image}
                                src="/images/no-wifi.png"
                            />    
                            <Typography className={classes.typo} variant="h5">{t('connection.no_connection')}</Typography>
                            <Typography className={classes.typo} variant="h6">{t('connection.no_connection_message')}</Typography>
                        </div>
                )}
            />
        </>
    )
}

export default CheckConnection