import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { Provider as StoreProvider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './store';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import cookies from 'js-cookie'
import './css/index.css';


// import { loadUser } from './actions/authActions';


const theme = createTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#aad0d9',
      dark: '#000'
    },
    secondary: {
      main: '#c7817d'
    }
  },
  text: {
    primary: '#7d7d7d'
  },
  typography: {
    useNextVariants: true
  }
});


const store = configureStore();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs:["en", "ar"],
    fallbackLng: "ar",
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    },
    backend:{
      loadPath: '/assets/locales/{{lng}}/translation.json',

    },
    react:{useSuspense: false},
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

ReactDOM.render(
  <StoreProvider store={store}>
    <App />
  </StoreProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
