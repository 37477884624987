/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  AppBar,
  Badge,
  Avatar,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Input,
  colors,
  Popper,
  Paper,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener
} from '@material-ui/core';
import Check from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import LockIcon from '@material-ui/icons/LockOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutline';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'src/utils/axios';
import NotificationsPopover from 'src/components/NotificationsPopover';
import PricingModal from 'src/components/PricingModal';
import { logout } from 'src/actions';
import ChatBar from './ChatBar';
import DirectionToggle from '../../components/DirectionToggle';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ClassNames } from '@emotion/react';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    background: theme.palette.background.navbar
  },
  flexGrow: {
    flexGrow: 1
  },
  flag:{
    maxWidth: 30,
    maxHeight: 30,
  },
  menu:{
    width: 160,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  text:{
    marginRight: 30,
    marginLeft: 30
  },
  circle: {
    '&:hover': {
      backgroundColor: "#7d7d7d"
    },
    backgroundColor: "#c7817d",
    borderRadius: "50%",
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: "50px",
    maxHeight: '50px',
    minWidth: '50px',
    maxWidth: '50px',
  },
  circleInner: {
    color: '#ffffff',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: "20px",
  },
  centerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    marrgin: '100px'
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  chatButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    transform: document.body.dir === 'rtl' ? 'rotate(180deg)' : null
   },
  textWhite: {
    padding: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: theme.spacing(3),
    color: theme.palette.white,
  },
  testSmall: {
    fontSize: '14px'
  },
  link:{
    textDecoration: 'none', 
    display: 'block', 
    color: '#000'
  },
  logoContainer: {
    marginInline: theme.spacing(6),
    width: '50px',
    height: '50px',
    background: '#fff',
    borderRadius:'50%',
  },
  logo: {
    width: '100%',
    height: '100%',
  },
  m20:{
    margin: '15px'
  }
}));

const popularSearches = [
  'Devias React Dashboard',
  'Devias',
  'Admin Pannel',
  'Project',
  'Pages'
];



function TopBar({ onOpenNavBarMobile, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const notificationsRef = useRef(null);
  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openChatBar, setOpenChatBar] = useState(false);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);

  const session = useSelector(state => state.auth.user);

  const handleLogout = () => {
    setAnchorEl(null);

    history.push('/auth/login');
    dispatch(logout());
  };

  const handlePricingModalOpen = () => {
    setPricingModalOpen(true);
  };

  const handlePricingModalClose = () => {
    setPricingModalOpen(false);
  };

  const handleChatBarOpen = () => {
    setOpenChatBar(true);
  };

  const handleChatBarClose = () => {
    setOpenChatBar(false);
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleSearchChange = event => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true);
      }
    } else {
      setOpenSearchPopover(false);
    }
  };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };

  // useEffect(() => {
  //   let mounted = true;

  //   const fetchNotifications = () => {
  //     axios.get('/api/account/notifications').then(response => {
  //       if (mounted) {
  //         setNotifications(response.data.notifications);
  //       }
  //     });
  //   };

  //   fetchNotifications();

  //   return () => {
  //     mounted = false;
  //   };
  
  // }, []);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);

    
    
  };

  const { t } = useTranslation();


  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/" className={classes.logoContainer}>
          <img
           alt="Tawajd Logo" className={classes.logo} src="/images/logos/Tawajd.svg" 
           onClick={() => window.location.reload()}/>
        </RouterLink>
        {/* <div className={classes.flexGrow} />
        <DirectionToggle /> */}
        <div className={classes.flexGrow} />
        {/* <Hidden smDown>
          <div className={classes.search} ref={searchRef}>
            <SearchIcon className={classes.searchIcon} />
            <Input
              className={classes.searchInput}
              disableUnderline
              onChange={handleSearchChange}
              placeholder="Search people &amp; places"
              value={searchValue}
            />
          </div>
          <Popper
            anchorEl={searchRef.current}
            className={classes.searchPopper}
            open={openSearchPopover}
            transition
          >
            <ClickAwayListener onClickAway={handleSearchPopverClose}>
              <Paper className={classes.searchPopperContent} elevation={3}>
                <List>
                  {popularSearches.map(search => (
                    <ListItem
                      button
                      key={search}
                      onClick={handleSearchPopverClose}
                    >
                      <ListItemIcon>
                        <SearchIcon />
                      </ListItemIcon>
                      <ListItemText primary={search} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </ClickAwayListener>
          </Popper>
          <Button
            className={classes.trialButton}
            onClick={handlePricingModalOpen}
            variant="contained"
          >
            <LockIcon className={classes.trialIcon} />
            Active
          </Button>
        </Hidden> */}
        {/* <IconButton
          className={classes.chatButton}
          color="inherit"
          onClick={handleChatBarOpen}
        >
          <Badge badgeContent={6} color="secondary">
            <PeopleIcon />
          </Badge>
        </IconButton> */}
          {/* <IconButton
            className={classes.notificationsButton}
            color="inherit"
            onClick={handleNotificationsOpen}
            ref={notificationsRef}
          >
            <Badge
              badgeContent={notifications.length}
              classes={{ badge: classes.notificationsBadge }}
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <Box className={classes.centerBox}>
        <Tooltip title={t('common.account_settings')}>
          <IconButton
            className={classes.circle}
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Typography className={classes.circleInner}>{session.fullName.substring(0,1).toUpperCase()}</Typography>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            //overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <RouterLink to="/profile" className = {classes.link}>
        <MenuItem className = {classes.m20}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="medium" />
          </ListItemIcon>
          <Typography className={classes.testSmall}>{t('nav_bar.profile')}</Typography>
        </MenuItem>
        </RouterLink>
        <Divider />
        <MenuItem className = {classes.m20} onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="medium" />
          </ListItemIcon>
          <Typography className={classes.testSmall}>{t('nav_bar.sign_out')}</Typography>
        </MenuItem>
      </Menu>
      
          
      </Toolbar>
      {/* <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      /> */}
      {/* <PricingModal onClose={handlePricingModalClose} open={pricingModalOpen} /> */}
      {/* <ChatBar onClose={handleChatBarClose} open={openChatBar} /> */}
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
