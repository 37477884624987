import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Fab } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',

    [theme.breakpoints.up('md')]: {
      /* top: 55%; */
      left: '30%',
      transform: 'translate(50%, 100%)',
      zIndex: theme.zIndex.drawer + 100
    },
    [theme.breakpoints.up('xs')]: {
      /* top: 55%; */
      right: '25%',
      transform: 'translate(50%, 20%)',
      zIndex: theme.zIndex.drawer + 100
    }
  }
}));

function DirectionToggle({ direction, onToggle }) {
  const classes = useStyles();

  return (
    <>
      <Fab
        className={classes.fab}
        color="primary"
        onClick={onToggle}
        size="small"
      >
        {direction}
      </Fab>
    </>
  );
}

DirectionToggle.propTypes = {
  direction: PropTypes.string,
  onToggle: PropTypes.func
};

export default DirectionToggle;
