import uuid from 'uuid/v1';
import moment from 'moment';
import mock from 'src/utils/mock';

let randomID = Math.floor(Math.random() * 100);
var time = '15:30';

let groups = [
  {
    id: randomID++,
    group_name: 'Group1',
    total_working_hours: 8,
    start_time: moment('08:00 am', 'HH:mm').format('hh:mm A'),
    end_time: moment('05:00 pm', 'HH:mm a').format('hh:mm A')
  },
  {
    id: randomID++,
    group_name: 'Group2',
    total_working_hours: 10,
    start_time: moment('10:00 am', 'HH:mm a').format('hh:mm A'),
    end_time: moment('08:00 pm', 'HH:mm a').format('hh:mm A')
  }
];
mock.onGet('/api/working-hours').reply(200, {
  groups: groups
});

// console.log(groups);

mock.onGet('/api/orders/1').reply(200, {
  order: {
    id: uuid(),
    ref: 'FAD107',
    promoCode: null,
    value: '55.25',
    currency: '$',
    status: 'canceled',
    customer: {
      name: 'Ekaterina Tankova',
      address: 'Street King William, 42456',
      city: 'Montgomery',
      country: 'United States'
    },
    items: [
      {
        id: uuid(),
        name: 'Project Points',
        cuantity: 25,
        billing: 'monthly',
        status: 'completed',
        value: '50.25',
        currency: '$'
      },
      {
        id: uuid(),
        name: 'Freelancer Subscription',
        cuantity: 1,
        billing: 'monthly',
        status: 'completed',
        value: '5.00',
        currency: '$'
      }
    ],
    created_at: moment()
  }
});
