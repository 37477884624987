import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import { returnErrors } from '../../actions/errorActions';
import { create } from "jss";
import rtl from "jss-rtl";
import {
  StylesProvider,
  jssPreset,
  ThemeProvider,
  createTheme
} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  TextField,
  Button,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    padding:15

  },
  actions: {
    justifyContent: 'flex-end'
  },
  imageContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  },
  center: {
    justifyContent: 'center'
  },
  image: {
    maxWidth: '25%',
    height: 'auto'
  },
}));

function SetNewPasswordModal({
  open,
  onClose,
  SetNewPasswordRequestCallback,
  className,
  ...rest
}) {


  const schema = {
    password: {
      presence: {
        allowEmpty: false,
        message:
          function (value) {
            return validate.format("^%{num} " + t('login_page.empty_password'), {
              num: value
            });
          }
      },
      format: {
        pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
        message: function (value) {
          return validate.format("^%{num} " + t('login_page.wrong_password'), {
            num: value
          });
        }
      },
    },
    confirm_new_password: {
      presence: {
        allowEmpty: false,
        message:
          function (value) {
            return validate.format("^%{num} " + t('login_page.empty_password'), {
              num: value
            });
          }
      },
      format: {
        pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
        message: function (value) {
          return validate.format("^%{num} " + t('login_page.wrong_password'), {
            num: value
          });
        }
      },
    },
  };

  const { t } = useTranslation();
  // Configure JSS
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();


  const [values, setValues] = useState({
    ...values
  });
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const error = useSelector(state => state.error);

  const [isError, setIsError] = useState(false);
  const [alert, setAlert] = useState({});
  const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />;


  const handleFieldChange = event => {
    event.persist();
    setValues(currentValues => ({
      ...currentValues,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    }));

    setFormState(prevFormState => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };


  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(prevFormState => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values]);

  const haneldAdd = values => {
    console.log(values.password)
    console.log(values.confirm_new_password)

    SetNewPasswordRequestCallback(values);
    setFormState({ isValid: false, values: {}, touched: {}, errors: {} });

  };

  useEffect(() => {
    if (error.status) {
      setIsLoading(false);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 2000);
    } else {
      // console.log(error);
      setIsLoading(false);

    }
    return () => {
      setIsLoading(false);
      setIsError(false);
      // console.log(error);
      // console.log(isLoading);
    };
  }, [error, setIsLoading, setIsError]);
  if (!open) {
    return null;
  }

  return (
    <Modal onClose={onClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form>
          <div className={classes.imageContainer}>
            <img
              alt="Under development"
              className={classes.image}
              src="/images/reset-password.png"
            />
          </div>
          <CardHeader />
          <Divider />
          <StylesProvider jss={jss}>
            <ThemeProvider theme={document.body.dir === 'rtl' ? { direction: "rtl" } : { direction: "ltr" }}>
              <CardContent>
                <Grid container spacing={3} className={classes.center}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={hasError('password')}
                      helperText={
                        hasError('password') ? formState.errors.password[0] : null
                      }
                      fullWidth
                      label={t('login_page.new_password')}
                      name="password"
                      onChange={handleFieldChange}
                      value={formState.values.password || ''}
                      variant="outlined"
                      type="password"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={hasError('confirm_new_password')}
                      helperText={
                        hasError('confirm_new_password') ? formState.errors.confirm_new_password[0] : null
                      }
                      fullWidth
                      label={t('login_page.confirm_new_password')}
                      name="confirm_new_password"
                      onChange={handleFieldChange}
                      value={formState.values.confirm_new_password || ''}
                      variant="outlined"
                      type="password"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </ThemeProvider>
          </StylesProvider>
          <Divider />
          <CardActions className={classes.actions}>
            <Button onClick={onClose}>{t('common.close')}</Button>
            <Button
              color="primary"
              onClick={() => haneldAdd(formState.values)}
              variant="contained"
              disabled={!formState.isValid}
            >
              {t('common.submit')}
            </Button>
          </CardActions>
          {isError && (
            <div>
              <Alert severity="error">{error.msg}</Alert>
            </div>
          )}
        </form>
      </Card>
    </Modal >
  );
}

SetNewPasswordModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

SetNewPasswordModal.defaultProps = {
  open: false,
  onClose: () => { }
};

export default SetNewPasswordModal;
