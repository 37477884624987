import React, { useState, useEffect } from 'react';
import { URL } from '../../../global'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Divider,
  Typography,
  TextField,
  Switch,
  Button
} from '@material-ui/core';
import { create } from "jss";
import rtl from "jss-rtl";
import {
  StylesProvider,
  jssPreset,
  ThemeProvider,
  createTheme
} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import axios from 'axios';

const schema = {
  fullName: {
    presence: { allowEmpty: false, message: 'is required' },
    format: {
      pattern: /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+(\s)*[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+(\s)*[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+(\s)*[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]*$/
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true
  },
  phoneNumber: {
    presence: { allowEmpty: false, message: 'is required' },
    format: {
      pattern: /^(009665|9665|\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
    }
  },
  jobTitle: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  jobId: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  workHoursName: {
    presence: { allowEmpty: false, message: 'Please choose group' }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    padding: 15

  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

function EmployeeEditModal({
  open,
  employee,
  onClose,
  onEditSave,
  className,
  ...rest
}) {
  const schema = {
    fullName: {
      presence: {
        allowEmpty: false,
        message:
          function (value) {
            return validate.format("^%{num} " + t('employees.empty_name'), {
              num: value
            });
          }
      },
      format: {
        pattern: /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+(\s)*[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+(\s)*[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+(\s)*[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]*$/,
        message: function (value) {
          return validate.format("^%{num} " + t('employees.wrong_name'), {
            num: value
          });
        }
      },
    },
    email: {
      presence: {
        allowEmpty: false,
        message:
          function (value) {
            return validate.format("^%{num} " + t('employees.empty_email'), {
              num: value
            });
          }
      },
      format: {
        pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        message: function (value) {
          return validate.format("^%{num} " + t('employees.wrong_email'), {
            num: value
          });
        }
      },
    },
    phoneNumber: {
      presence: {
        allowEmpty: false,
        message:
          function (value) {
            return validate.format("^%{num} " + t('employees.empty_phone'), {
              num: value
            });
          }
      },
      format: {
        pattern: /^([0-9]\d{9}|\d{10}|\d{11}|\d{12}|\d{13}|\d{14})$/,
        message: function (value) {
          return validate.format("^%{num} " + t('employees.wrong_phone'), {
            num: value
          });
        }
      },
    },
    jobTitle: {
      presence: {
        allowEmpty: false,
        message:
          function (value) {
            return validate.format("^%{num} " + t('employees.empty_job_title'), {
              num: value
            });
          }
      },
    },
    jobId: {
      presence: {
        allowEmpty: false,
        message:
          function (value) {
            return validate.format("^%{num} " + t('employees.empty_job_id'), {
              num: value
            });
          }
      },
    },
    // location: {
    //   presence: { allowEmpty: false, message: 'Please choose location' }
    // },
    workHoursName: {
      presence: {
        allowEmpty: false,
        message:
          function (value) {
            return validate.format("^%{num} " + t('employees.empty_group'), {
              num: value
            });
          }
      },
    }
  };

  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [workHours, setWorkHours] = useState([]);
  const [values, setValues] = useState({ ...employee });
  const [workingHoursID, setWorkingHoursID] = useState();

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...employee },
    touched: {},
    errors: {}
  });
  const { t } = useTranslation();

  // Configure JSS
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  // console.log(employee);

  const token = localStorage.getItem('token');


  useEffect(() => {

    const header = { Authorization: `Bearer ${token}` };

    const params = {
      pageNumber: 1,
      pageSize: 100000
    };

    const fetchLocations = axios.get(`${URL}/api/Locations`, {
      headers: header,
      params: params
    });
    const fetchWorkHours = axios.get(`${URL}/api/WorkHours`, {
      headers: header,
      params: params
    });

    Promise.all([fetchLocations, fetchWorkHours]).then(responses => {
      return Promise.all(
        responses.map(response => {
          if (response.status === 200) {
            return response.data
          } else {
            throw response;
          }
        })
      )
        .then(data => {
          console.log('data');
          console.log(data);
          setLocations(data[0].items);
          setWorkHours(data[1].items);
        })
        .catch(err => {
          console.log(err);
        });
    });
  }, [formState.values]);

  useEffect(() => {
    setFormState(prevFormState => ({
      ...prevFormState,
      values: { ...employee }
    }));
    return () => {
      // console.log(formState.values.workHoursName);
      setFormState(prevFormState => ({
        ...prevFormState,
        values: null
      }));
    };

    // console.log('formState.values', formState.values);
  }, [employee]);

  const haneldEdit = (values) => {
    console.log('Submit')
    console.log(values.id)

    onEditSave(values);
    // setValues({});
    //setFormState({ isValid: false, values: {}, touched: {}, errors: {} });
  };

  const handleFieldChange = event => {
    event.persist();
    // setValues(currentValues => ({
    //   id: randomID++,
    //   ...currentValues,
    //   [event.target.name]:
    //     event.target.type === 'checkbox'
    //       ? event.target.checked
    //       : event.target.value
    // }));

    setFormState(prevFormState => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
    // console.log(formState);
  };

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field]);


  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(prevFormState => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values]);

  // console.log(formState.values);

  return (
    <Modal onClose={onClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form>
          <CardHeader title={t('employees.edit_employee')} />
          <Divider />
          <CardContent>
            <StylesProvider jss={jss}>
              <ThemeProvider theme={document.body.dir === 'rtl' ? { direction: "rtl" } : { direction: "ltr" }}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={hasError('fullName')}
                      helperText={
                        hasError('fullName') ? formState.errors.fullName[0] : null
                      }
                      required
                      fullWidth
                      label={t('employees.name')}
                      name="fullName"
                      onChange={handleFieldChange}
                      value={formState.values.fullName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={hasError('email')}
                      helperText={
                        hasError('email') ? formState.errors.email[0] : null
                      }
                      required
                      fullWidth
                      label={t('employees.email')}
                      name="email"
                      onChange={handleFieldChange}
                      value={formState.values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={hasError('phoneNumber')}
                      helperText={
                        hasError('phoneNumber') ? formState.errors.phoneNumber[0] : null
                      }
                      required
                      fullWidth
                      label={t('employees.phone')}
                      name="phoneNumber"
                      onChange={handleFieldChange}
                      value={formState.values.phoneNumber}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={hasError('jobTitle')}
                      helperText={
                        hasError('jobTitle') ? formState.errors.jobTitle[0] : null
                      }
                      required
                      fullWidth
                      label={t('employees.job_title')}
                      name="jobTitle"
                      onChange={handleFieldChange}
                      value={formState.values.jobTitle || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={hasError('jobId')}
                      helperText={
                        hasError('jobId') ? formState.errors.jobId[0] : null
                      }
                      required
                      fullWidth
                      label={t('employees.job_ID')}
                      name="jobId"
                      onChange={handleFieldChange}
                      value={formState.values.jobId || ''}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      className={classes.field}
                      fullWidth
                      required
                      label={t('employees.working_group')}
                      margin="dense"
                      name="workHoursName"
                      onChange={event =>
                        handleFieldChange(
                          event,
                          'workHoursName',
                          event.target.value
                        )
                      }
                      select
                      // eslint-disable-next-line react/jsx-sort-props
                      SelectProps={{ native: true }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={formState.values.workHoursName}
                      variant="outlined"

                    >
                      {/* <option disabled value="" /> */}
                      {workHours.map(option => (

                        <option id={option.id} value={option.name}>
                          {option.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </ThemeProvider>
            </StylesProvider>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button onClick={onClose}>{t('common.close')}</Button>
            <Button
              color="primary"
              onClick={() => haneldEdit(formState.values)}
              variant="contained"
              disabled={!formState.isValid}
            >
              {t('common.save')}
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
}

EmployeeEditModal.propTypes = {
  className: PropTypes.string,
  employee: PropTypes.any,
  onClose: PropTypes.func,
  onEditSave: PropTypes.func,
  open: PropTypes.bool
};

EmployeeEditModal.defaultProps = {
  open: false,
  onClose: () => { }
};

export default EmployeeEditModal;


