import axios from 'axios';

// const instance = axios.create();

const instance = axios.create({
  baseURL: 'http://18.184.129.69/api'
  /* other custom settings */
});

export default instance;
