import React, { useState } from 'react';
import { useRef, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import '@progress/kendo-theme-default/dist/all.css';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import InputLabel from '@mui/material/InputLabel';
import {
    Modal,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Typography,
    TextField,
    Switch,
    Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/styles';
import { borderRadius, margin } from '@mui/material/node_modules/@mui/system';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import {
    Chart,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
} from "@progress/kendo-react-charts";
import { URL } from '../../global'
import { useTranslation } from "react-i18next";
import "../../App.css";

const useStyles = makeStyles(theme => ({
    root: {},
    container: {
        direction: 'ltr',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        hieght: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '30px',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        hieght: '100%',
        borderRadius: '5px',
        border: '2px',
        borderColor: '#000',
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
        padding: '30px'
    },
    section: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center'
    },
    item: {
        margin: '10px',
        width: '200px',
    },
    PDFContainer: {
        fontFamily: 'Cairo',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        hieght: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        justifyContent: 'flex-start',
        width: '100%',
    },
    logoContainer: {
        marginInline: theme.spacing(6),
        width: '50px',
        height: '50px',
        borderRadius: '50%',
    },
    logo: {
        width: '100%',
        height: '100%',
    },
    button: {
        width: '40%',
        margin: '30px'
    },
    chart: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '30px'
    }
}));

const data = [
    {
        "kind": "Hydroelectric",
        "share": 0.175
    },
    {
        "kind": "Nuclear",
        "share": 0.238
    },
    {
        "kind": "Coal",
        "share": 0.118
    },
    {
        "kind": "Solar",
        "share": 0.052
    },
    {
        "kind": "Wind",
        "share": 0.225
    },
    {
        "kind": "Other",
        "share": 0.192
    }
]


//const labelContent = (e) => e.category;
const categories = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"];
const firstSeries = [123, 276, 310, 212, 240, 156, 98];
const secondSeries = [165, 210, 287, 144, 190, 167, 212];
const thirdSeries = [56, 140, 195, 46, 123, 78, 95];


function PDFViewer(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [attendanceRecords, setAttendanceRecords] = useState()
    const [filteredAttendanceRecords, setFilteredAttendanceRecords] = useState([])
    const [per, setPer] = useState([])
    const [lastMonth, setLastMonth] = useState(null)
    const [currentMonth, setCurrentMonth] = useState(null)


    const container = React.useRef(null);
    const PDFExportComponent = useRef(null)

    const handleDownload = (event) => {
        console.log("Download")
        PDFExportComponent.current.save()
    }
    const location = useLocation();
    console.log(props, "props")
    console.log(location, " useLocation Hook");

    const employeeInfo = location.state?.employee;
    console.log(employeeInfo)

    const token = localStorage.getItem('token');


    useEffect(() => {
        const downloadRecord = () => {
            fetch(`${URL}/api/Attendance/${employeeInfo.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        //setAlert({ type: 'success', status: t('messages.records_downloaded'), open: true });
                        return res.json();
                    } else {
                        //setAlert({ type: 'error', status: t('errors.failed_download_records'), open: true });
                    }
                })
                .then(data => {
                    console.log(data);
                    setAttendanceRecords(data.presentsAndLeaves)
                })
                .catch(err => {
                    //setAlert({ type: 'error', status: t('errors.failed_download_records'), open: true });
                });
        };
        downloadRecord();
    }, []);


    useEffect(() => {
        console.log("Attendance Records")
        console.log(attendanceRecords)


        var lastMonth = new Date().getMonth() <= 9 ? '0' + new Date().getMonth() : new Date().getMonth()
        var currentMonth = new Date().getMonth() <= 9 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
        var lastYear = new Date().getFullYear() - 1
        var currentYear = new Date().getFullYear()

        const eventsLast = attendanceRecords?.filter((e) => {
            var lastMonthData = lastMonth === 12 ? lastYear + '-' + lastMonth : currentYear + '-' + lastMonth;
            setLastMonth(lastMonthData)


            return e.createdAt.indexOf(lastMonthData) !== -1
        });
        const eventsCurrent = attendanceRecords?.filter((e) => {
            var currentMonthData = currentYear + '-' + currentMonth;
            setCurrentMonth(currentMonthData)


            return e.createdAt.indexOf(currentMonthData) !== -1
        });

        let _filteredAttendanceRecords = [];


        eventsCurrent?.reverse().map(function (item) {
            _filteredAttendanceRecords.push({
                startDay: item.startDay + ", " + item.createdAt.substring(0, 10) + ", " + item.presentTime,
                endDay: item.endDay + ", " + item.updatedAt.substring(0, 10) + ", " + item.departureTime,
                spendTime: item.spendTime,
                statusEn: item.statusEn,
                locationName: item.locationName
            });
        })

        eventsLast?.reverse().map(function (item) {
            _filteredAttendanceRecords.push({
                startDay: item.startDay + ", " + item.createdAt.substring(0, 10) + ", " + item.presentTime,
                endDay: item.endDay + ", " + item.updatedAt.substring(0, 10) + ", " + item.departureTime,
                spendTime: item.spendTime,
                statusEn: item.statusEn,
                locationName: item.locationName
            });
        })

        var total = 0;
        _filteredAttendanceRecords.forEach((item) => (item.statusEn && total++));

        var completed = 0;
        _filteredAttendanceRecords.forEach((item) => (item.statusEn === "Completed" && completed++));

        var uncompleted = 0;
        _filteredAttendanceRecords.forEach((item) => (item.statusEn === "Uncompleted" && uncompleted++));

        var absent = 0;
        _filteredAttendanceRecords.forEach((item) => (item.statusEn === "Absent" && absent++));

        var leave = 0;
        _filteredAttendanceRecords.forEach((item) => (item.statusEn === "Leave" && leave++));

        var late = 0;
        _filteredAttendanceRecords.forEach((item) => (item.statusEn === "Late" && late++));

        console.log((completed / total) * 100)
        console.log((uncompleted / total) * 100)

        const data = [
            {
                "status": "Absent",
                "number": absent,
                "percentage": absent / total
            },
            {
                "status": "Late",
                "number": late,
                "percentage": late / total
            },
            {
                "status": "Completed",
                "number": completed,
                "percentage": completed / total

            },
            {
                "status": "Uncompleted",
                "number": uncompleted,
                "percentage": uncompleted / total
            },
            {
                "status": "Leave",
                "number": leave,
                "percentage": leave / total
            }
        ]


        setPer(data)
        setFilteredAttendanceRecords(_filteredAttendanceRecords)
    }, [attendanceRecords]);

    const labelContent = (props) => {
        let formatedNumber = Number(props.dataItem.percentage).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.dataItem.status} : (${props.dataItem.number}) - ${formatedNumber}`;
    };


    return (
        <div className={classes.container}>
            <PDFExport ref={PDFExportComponent} paperSize="Tabloid" fileName={employeeInfo?.fullName + " - " + employeeInfo?.jobId}>
                <div className={classes.PDFContainer}>
                    <div className={classes.infoContainer}>
                        <div className={classes.header}>
                            <h1>Attendance Records</h1>
                            <RouterLink to="/" className={classes.logoContainer}>
                                <img
                                    alt="Tawajd Logo" className={classes.logo} src="/images/logos/Tawajd.svg"
                                    onClick={() => window.location.reload()} />
                            </RouterLink>
                        </div>
                    </div>
                    {/* <div className={classes.infoContainer}>
                        <div className={classes.text}>
                            <h3 >Employee Information</h3>
                            <Divider />
                        </div>
                        <div>
                            <div className={classes.section}>
                                <div className={classes.item}>
                                    <h4>Name</h4>
                                    <p>{employeeInfo?.fullName}</p>
                                </div>
                                <div className={classes.item}>
                                    <h4>Company Name</h4>
                                    <p>{employeeInfo?.companyName}</p>
                                </div>
                            </div>
                            <div className={classes.section}>
                                <div className={classes.item}>
                                    <h4>Email</h4>
                                    <p>{employeeInfo?.email}</p>
                                </div>
                                <div className={classes.item}>
                                    <h4>Phone Number</h4>
                                    <p>{employeeInfo?.phoneNumber}</p>
                                </div>
                            </div>
                            <div className={classes.section}>
                                <div className={classes.item}>
                                    <h4>Job ID</h4>
                                    <p>{employeeInfo?.jobId}</p>
                                </div>
                                <div className={classes.item}>
                                    <h4>Job Title</h4>
                                    <p>{employeeInfo?.jobTitle}</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className={classes.infoContainer}>
                        <div className={classes.text}>
                            <h3 >Employee Information</h3>
                            <Divider />
                        </div>
                        <div>
                            <div className={classes.section}>
                                <div className={classes.item}>
                                    <h4>Employee ID</h4>
                                    <p>{employeeInfo?.jobId}</p>
                                </div>
                                <div className={classes.item}>
                                    <h4>Email</h4>
                                    <p>{employeeInfo?.email}</p>
                                </div>
                                <div className={classes.item}>
                                    <h4>Phone Number</h4>
                                    <p>{employeeInfo?.phoneNumber}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.infoContainer}>
                        <div className={classes.text}>
                            <h3>Monthly Report</h3>
                            <h6>{lastMonth === null & currentMonth === null ? 'There are no data for the last two months!' : 'From ' + lastMonth + ' to ' + currentMonth}</h6>
                            <Divider />
                        </div>
                        <div>
                            <div ref={container}>
                                <Grid
                                    style={{
                                        maxHeight: "100%",
                                        marginTop: "20px"
                                    }}
                                    data={filteredAttendanceRecords}
                                >
                                    <Column field="startDay" title={"Check In" + "\xa0\xa0\xa0"} width="180px" />
                                    <Column field="endDay" title={"Check Out" + "\xa0\xa0\xa0"} width="180px" />
                                    <Column field="spendTime" title={"Total(H)" + "\xa0\xa0\xa0"} width="90px" />
                                    <Column field="statusEn" title={"Status" + "\xa0\xa0\xa0"} width="100px" />
                                    <Column field="locationName" title={"Location" + "\xa0\xa0\xa0"} width="160px" />
                                </Grid>
                            </div>
                        </div>
                        <div className={classes.chart}>
                            <Chart>
                                <ChartSeries>
                                    <ChartSeriesItem
                                        type="pie"
                                        data={per}
                                        categoryField="status"
                                        field="number"
                                        autoFit={true}
                                        colorField="color"
                                        labels={{
                                            visible: true,
                                            content: labelContent,
                                            color: '#000000'

                                        }}
                                    >
                                    </ChartSeriesItem>
                                </ChartSeries>
                                <ChartLegend visible={true} />
                                <ChartLegend position="bottom" />

                            </Chart>
                        </div>
                    </div>
                </div>
            </PDFExport>
            <Button color="primary" variant="contained" className={classes.button} onClick={handleDownload}>
                {t('common.download')}
            </Button>
        </div>
    );
}

export default PDFViewer
