import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { CSVLink } from "react-csv"
import LinearProgress from '@mui/material/LinearProgress';
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  Box,
  List,
  ListItem,
  ListItemIcon,
  TableRow,
  Typography,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'src/utils/axios';
import getInitials from 'src/utils/getInitials';
import ReviewStars from 'src/components/ReviewStars';
import GenericMoreButton from 'src/components/GenericMoreButton';
import TableEditBar from 'src/components/TableEditBar';
import Paginate from 'src/components/Paginate';
import EmployeeEditModal from '../EmployeesManagementDetails/Summary/EmployeeEditModal';
import { useTranslation } from "react-i18next";
import PDFViewer from './PDFViewer';
import { color } from '@mui/material/node_modules/@mui/system';

const useStyles = makeStyles(theme => ({
  root: {},
  noFound: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(6)
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  paginate: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  },
  cell: {
    textAlign: document.body.dir === 'rtl' ? "right" : "left",

  },
  iconContainer: {
    width: '35px',
    height: '35px',
    margin: '10px'
  },
  icon: {
    width: '35px',
    height: '35px',
  },
  m5: {
    margin: "5px",
    color: "#000",
  },
  progress: {
    color: '#aad0d9',
  },
}));

function Results({
  className,
  employees,
  handleDownload,
  loading,
  downloading,
  setDownloading,
  csvData,
  csvInstance,
  attendanceRecord,
  ...rest
}) {
  const classes = useStyles();
  const { t } = useTranslation();


  const [selectedemployees, setSelectedCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openEdit, setOpenEdit] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [employeeData, setEmployeeData] = useState([]);


  const token = localStorage.getItem('token');

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);



  };

  const handleChangePage = event => {
    setPage(event.selected);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const notFound = () => (
    <div className={classes.noFound}>
      <Typography color="primary" gutterBottom variant="h5">
        {t('employees.no_employees_found')}
      </Typography>
    </div>
  );

  // useEffect(() => {
  //   // console.log(downloading);
  // }, [downloading]);

  const DownloadRecord = (id, fullName, jobId) => {
    console.log("DATA")

    const data = {
      employeeInfo: fullName + " - " + jobId,
      employeeID: id,
      employeeFullName: fullName,
      employeeJobId: jobId
    }
    setEmployeeData(data)

    console.log(data)
    console.log("data new")
    console.log(csvData)

    handleDownload(id)
  }


  const csvHeader = [
    { label: 'Check In Day', key: 'startDay' },
    { label: 'Check In Date', key: 'createdAt' },
    { label: 'Check In Time', key: 'presentTime' },
    { label: 'Check Out Day', key: 'endDay' },
    { label: 'Check Out Date', key: 'updatedAt' },
    { label: 'Check Out Time', key: 'departureTime' },
    { label: 'Total Hours Spent', key: 'spendTime' },
    { label: 'Status AR', key: 'statusAr' },
    { label: 'Status EN', key: 'statusEn' },
    { label: 'Location', key: 'locationName' }
  ]

  const csvReport = {
    filename: employeeData.employeeInfo,
    Name: employeeData.employeeFullName,
    headers: csvHeader,
    data: csvData
  }


  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {employees.filter(a => a.isAcitve == true).length}
        {t('common.records')}
        {page + 1}
        {t('common.of')}
        {Math.ceil(employees.filter(a => a.isAcitve == true).length / rowsPerPage)}
      </Typography>

      <Card>
        {/* <CardHeader action={<GenericMoreButton />} title="All employee" /> */}
        <Divider />

        {loading === true ?
          <LinearProgress color="inherit" className={classes.progress} />
          : !employees.filter(a => a.isAcitve == true).length ?
            (
              notFound()
            ) : (
              <CardContent className={classes.content}>
                <PerfectScrollbar>
                  <div className={classes.inner}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell className={classes.cell}>{t('common.name')}</TableCell>
                          <TableCell className={classes.cell}>{t('employees.phone')}</TableCell>
                          {/* <TableCell>Location</TableCell> */}
                          <TableCell className={classes.cell}>{t('employees.working_group')}</TableCell>
                          <TableCell align="center">{t('attendance.download_records')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {employees.filter(a => a.isAcitve == true).sort((a, b) => a.fullName.localeCompare(b.fullName))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((employee, index) => (
                            <TableRow
                              hover
                              key={employee.id}
                              selected={
                                selectedemployees.indexOf(employee.id) !== -1
                              }
                            >
                              <TableCell>
                                {/* {employee.id} */}

                                <Avatar
                                  className={classes.avatar}
                                  src={employee.avatar}
                                >
                                  {getInitials(employee.name)}
                                </Avatar>
                              </TableCell>
                              <TableCell className={classes.cell}>
                                <div className={classes.nameCell}>
                                  {/* <Avatar
                              className={classes.avatar}
                              src={employee.avatar}
                            >
                              {getInitials(employee.name)}
                            </Avatar> */}
                                  <div>
                                    {employee.fullName}

                                    {/* <div>{employee.email}</div> */}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className={classes.cell}>{employee.phoneNumber}</TableCell>
                              {/* <TableCell>{employee.locationName}</TableCell> */}
                              <TableCell className={classes.cell}>{employee.workHoursName}</TableCell>

                              <TableCell align="center">
                                {/* <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to="/management/employees/1"
                          variant="outlined"
                        >
                          View */}
                                {/* <IconButton
                              edge="end"
                              component={RouterLink}
                              size="small"
                              to="/management/employees/1"
                            >
                              <VisibilityIcon color="primary" />
                            </IconButton> */}

                                {/* menu choise */}
                                {/* <Box className={classes.centerBox}>
                                  <Tooltip title={t('common.download')}>
                                    <IconButton
                                      className={classes.circle}
                                      onClick={handleClick}
                                      size="small"
                                      sx={{ ml: 2 }}
                                      aria-controls={open ? 'account-menu' : undefined}
                                      aria-haspopup="true"
                                      aria-expanded={open ? 'true' : undefined}
                                    >
                                      <GetAppIcon color="primary" fontSize="large" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                                <Menu
                                  anchorEl={anchorEl}
                                  id="account-menu"
                                  open={open}
                                  onClose={handleClose}
                                  onClick={handleClose}
                                  PaperProps={{
                                    elevation: 0,
                                    sx: {
                                      //overflow: 'visible',
                                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                      mt: 1.5,
                                      '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                      },
                                      '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                      },
                                    },
                                  }}
                                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                  <MenuItem
                                    disabled={downloading}
                                    onClick={() => DownloadRecord(employee.id, employee.fullName, employee.jobId)}
                                  >
                                    <ListItemIcon>
                                      {downloading ? (
                                        <CircularProgress
                                          className={classes.circularProgress}
                                          size={20}
                                          disabled
                                        />
                                      ) : (
                                        <img className={classes.iconContainer} src="./images/files/csv.png" />
                                      )}
                                    </ListItemIcon>
                                    <Typography className={classes.m5}>CSV</Typography>
                                  </MenuItem>
                                  <Divider />
                                  <RouterLink to={{
                                    pathname: "/pdfViewer",
                                    state: { employee: employee }
                                  }}>

                                    <MenuItem>
                                      <ListItemIcon>
                                        {downloading ? (
                                          <CircularProgress
                                            className={classes.circularProgress}
                                            size={20}
                                            disabled
                                          />
                                        ) : (
                                          <img className={classes.iconContainer} src="./images/files/pdf.png" />
                                        )}
                                      </ListItemIcon>
                                      <Typography className={classes.m5}>PDF</Typography>
                                    </MenuItem>
                                  </RouterLink>
                                </Menu> */}


                                <React.Fragment>
                                  {/* <IconButton
                                    // edge="end"
                                    disabled={downloading}
                                    onClick={() => DownloadRecord(employee.id, employee.fullName, employee.jobId)}


                                  >
                                    {downloading ? (
                                      <CircularProgress
                                        className={classes.circularProgress}
                                        size={20}
                                        disabled
                                      />
                                    ) : (
                                      <GetAppIcon color="primary" fontSize="large" />
                                    )}
                                  </IconButton> */}

                                  <IconButton
                                    // edge="end"
                                    disabled={downloading}
                                    onClick={() => DownloadRecord(employee.id, employee.fullName, employee.jobId)}


                                  >
                                    {downloading ? (
                                      <CircularProgress
                                        className={classes.circularProgress}
                                        size={20}
                                        disabled
                                      />
                                    ) : (
                                      <IconButton className={classes.iconContainer}>
                                        {downloading ? (
                                          <CircularProgress
                                            className={classes.circularProgress}
                                            size={20}
                                            disabled
                                          />
                                        ) : (
                                          <img className={classes.icon} src="./images/files/csv.png" />
                                        )}
                                      </IconButton>)}
                                  </IconButton>
                                  <RouterLink to={{
                                    pathname: "/pdfViewer",
                                    state: { employee: employee }
                                  }}>
                                    <IconButton>


                                      <IconButton className={classes.iconContainer}>
                                        {downloading ? (
                                          <CircularProgress
                                            className={classes.circularProgress}
                                            size={20}
                                            disabled
                                          />
                                        ) : (
                                          <img className={classes.icon} src="./images/files/pdf.png" />
                                        )}
                                      </IconButton>
                                    </IconButton>
                                  </RouterLink>


                                  {csvData.length > 0 ?
                                    <CSVLink
                                      {...csvReport}
                                      ref={csvInstance}

                                    />
                                    : undefined}
                                </React.Fragment>
                                {/* <CSVLink {...csvReport}>
                            <IconButton
                              // edge="end"
                              disabled={downloading}
                              //onClick={() => handleDownload(employee.id)}
                              onClick={async () => {
                                
                                await handleDownload(employee.id)
                              }}
                              
                            >
                              {downloading ? (
                                <CircularProgress
                                  className={classes.circularProgress}
                                  size={20}
                                  disabled
                                />
                              ) : (
                                <GetAppIcon color="primary" fontSize="large" />
                              )}
                            </IconButton>
                            </CSVLink> */}
                                {/* <IconButton
                              edge="end"
                              onClick={() => handleDelete(employee.id)}
                            >
                              <DeleteIcon color="error" />
                            </IconButton> */}
                                {/* </Button> */}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </div>
                </PerfectScrollbar>
              </CardContent>

            )}

        <div className={classes.paginate}>
          <Paginate
            pageCount={Math.ceil(employees.filter(a => a.isAcitve == true).length / rowsPerPage)}
            onPageChange={handleChangePage}
          />
        </div>
      </Card>

      {/* <TableEditBar selected={selectedemployees} /> */}
    </div>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  employees: PropTypes.array,
  handleDownload: PropTypes.func,
  loading: PropTypes.bool,
  downloading: PropTypes.bool,
  setDownloading: PropTypes.func,
  csvData: PropTypes.array,
  attendanceRecord: PropTypes.array,
  csvInstance: PropTypes.func,
};

export default Results;
