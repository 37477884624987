import React, { useState, useEffect, useContext } from 'react';
import { Router, Redirect } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { ReactReduxContext, useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { theme, themeWithRtl } from './theme';
import { configureStore } from './store';
import routes from './routes';
import GoogleAnalytics from './components/GoogleAnalytics';
import CookiesNotification from './components/CookiesNotification';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import DirectionToggle from './components/DirectionToggle';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/main.scss';
import { loadUser } from './actions/authActions';
import { fromJS } from 'immutable';
import CheckConnection from "./components/CheckConnection"
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie'
import { createMuiTheme } from '@material-ui/core';

const history = createBrowserHistory();

// const store = configureStore();

function App() {
  

  const dispatch = useDispatch();
  const [direction, setDirection] = useState('ltr');

  const store = configureStore();

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  const session = useSelector(state => state.auth);


  // {
  //   !session.isAuthenticated ? (
  //     <Router history={history}>{renderRoutes(routes)}</Router>
  //   ) : (
  //     <Router history={history}>
  //       {/* <ScrollReset /> */}
  //       {/* <GoogleAnalytics /> */}
  //       {/* <CookiesNotification /> */}
  //       {/* <DirectionToggle
  //  direction={direction}
  //  onToggle={handleDirecitonToggle}
  // /> */}
  //       {renderRoutes(routes)}
  //     </Router>
  //   );
  // }

  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#aad0d9',
        main: '#aad0d9',
        dark: '#aad0d9',
        contrastText: '#fff',
      },
      secondary: {
        light: '#aad0d9',
        main: '#aad0d9',
        dark: '#aad0d9',
        contrastText: '#fff',
      },
    },
    typography: {
      allVariants: {
        fontFamily: 'Cairo',
      },
      h5:{
        fontSize: 15,
        fontFamily: 'Cairo',


      },
      h3:{
        fontSize: 40
      },
      h2:{
        fontSize: 50

      },
    },
    
  });
  return (
    //<CheckConnection>
    <ThemeProvider theme={direction === 'rtl' ? themeWithRtl : theme}>
      <StylesProvider direction={direction}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>

            <ScrollReset />
            {/* <GoogleAnalytics /> */}
            {/* <CookiesNotification /> */}
            {/* <DirectionToggle
              direction={direction}
              onToggle={handleDirecitonToggle}
            />
            */}
            {renderRoutes(routes, session)}
          </Router>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
    //</CheckConnection>
  );
}

export default App;
