/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CSVLink } from "react-csv"
import { makeStyles } from '@material-ui/styles';
import { Container, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from "react-i18next";
import { URL } from '../../global'
import axios from 'axios';
import Page from 'src/components/Page';
import SearchBar from 'src/components/SearchBar';
import Header from './Header';
import Results from './Results';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  alert: {
    "& .MuiAlert-icon": {
      marginLeft: 10,
      marginRight: 10
    },
    "& .MuiAlert-action": {
      marginLeft: 10,
      marginRight: 10
    }

  },
}));

function AttendanceRecords() {
  const classes = useStyles();
  const [employees, setEmployees] = useState([]);
  const [filterdEmployees, setFilterdEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [attendanceRecord, setAttendanceRecord] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [csvData, setCsvData] = useState([]);
  const csvInstance = useRef();
  const { t } = useTranslation();



  const [alert, setAlert] = useState({});

  const token = localStorage.getItem('token');

  const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert({ type: '', status: '', open: false });
  };

  const handleFilter = () => { };

  const handleSearch = e => {
    // console.log(e.target.value);
    const searchTesrm = e.target.value;

    setSearchTerm(searchTesrm);
  };


  const handleDownload = id => {
    // console.log(id);
    setDownloading(true);

    const downloadRecord = () => {
      fetch(`${URL}/api/Attendance/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          console.log(data.presentsAndLeaves.length);
          if (data.presentsAndLeaves.length === 0) {
            setAlert({ type: 'error', status: t('errors.failed_download_records'), open: true });
          } else {
            setAlert({ type: 'success', status: t('messages.records_downloaded'), open: true });
          }

          setCsvData(data.presentsAndLeaves);
          setDownloading(false);
        })

        .catch(err => {
          setAlert({ type: 'error', status: t('errors.failed_download_records'), open: true });
        });

      setDownloading(false);
    };

    downloadRecord();
  };
  // useEffect(() => {
  //   setCsvData(csvData)
  // }, [csvData])
  useEffect(() => {
    if (csvData && csvInstance && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsvData([]);
      });
    }
  }, [csvData]);

  useEffect(() => {
    // console.log(token);
    const GetEmployees = async () => {

      const header = { Authorization: `Bearer ${token}` };

      const params = {
        pageNumber: 1,
        pageSize: 100000
      };

      const employees = await axios.get(`${URL}/api/Employee`, {
        headers: header,
        params: params
      });
      setLoading(false);
      setEmployees(employees.data.items);
      setFilterdEmployees(employees.data.items);
    }

    GetEmployees()
  }, []);

  useEffect(() => {
    const employeesBySearch = employees.filter(employee => {
      return employee.fullName
        .toLowerCase()
        .includes(searchTerm.toLowerCase().trim());
      //    ||
      //   employee.location
      //     .toLowerCase()
      //     .includes(searchTerm.toLowerCase().trim())
      //      ||
      //   employee.type.toLowerCase().includes(searchTerm.toLowerCase().trim())
      //
    });

    setFilterdEmployees(employeesBySearch);
  }, [searchTerm]);

  return (
    <Page className={classes.root} title="Attendance Records">
      <Container maxWidth={false}>
        <Header />
        <SearchBar onSearch={handleSearch} />
        {filterdEmployees && (
          <Results
            className={classes.results}
            employees={filterdEmployees}
            handleDownload={handleDownload}
            loading={loading}
            downloading={downloading}
            setDownloading={setDownloading}
            csvData={csvData}
            csvInstance={csvInstance}
          />
        )}
        {alert.type && (
          <Snackbar
            open={alert.open}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            {alert.type === 'success' ? (
              <Alert className={classes.alert} onClose={handleClose} severity={alert.type}>
                {`${alert.status}`}
              </Alert>
            ) : (
              <Alert className={classes.alert} onClose={handleClose} severity={alert.type}>
                {`${alert.status}`}
              </Alert>
            )}
          </Snackbar>
        )}
      </Container>
    </Page>
  );
}

export default AttendanceRecords;
