import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Button,
  Grid,
  colors,
  IconButton,
  Card,
  CardContent,
  Link
} from '@material-ui/core';
import {
  KeyboardArrowRightIcon,
  PeopleOutlined,
  AccessTime,
  LocationOn,
  CalendarToday
} from '@material-ui/icons';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import { useTranslation } from "react-i18next";


import axios from 'src/utils/axios';

// import MenuCard from './MenuCard';

const useStyles = makeStyles(theme => ({
  root: {},

  cards: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  cardBody: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
    // marginBottom: theme.spacing(2)
  },
  title: {
    position: 'relative',
    '&:before': {
      position: 'absolute',
      bottom: -8,
      left: 0,
      content: '" "',
      height: 3,
      width: 48,
      backgroundColor: theme.palette.primary.main
    }
  },
  actions: {
    marginBlockStart: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  arrowIcon: {
    marginLeft: theme.spacing(1)
  }
}));

function MenuCardList({ className, ...rest }) {
  const { t } = useTranslation();

  const classes = useStyles();
  const [menuCard, setMenuCard] = useState([
    {
      cardName: t('nav_bar.employees'),
      link: '/employees',
      icon: PeopleOutlined
    },
    {
      cardName: t('nav_bar.working_hours'),
      link: '/working-hours',
      icon: AccessTime
    },
    {
      cardName: t('nav_bar.remote_work'),
      link: '/remote-work',
      icon: HomeWorkIcon
    },
    {
      cardName: t('nav_bar.locations'),
      link: '/locations',
      icon: LocationOn
    },
    {
      cardName: t('nav_bar.attendance_records'),
      link: '/attendance-records',
      icon: CalendarToday
    }
  ]);

  // useEffect(() => {
  //   let mounted = true;

  //   const fetchProjects = () => {
  //     axios.get('/api/account/projects').then(response => {
  //       if (mounted) {
  //         setProjects(response.data.projects);
  //       }
  //     });
  //   };

  //   fetchProjects();

  //   return () => {
  //     mounted = false;
  //   };
  // }, []);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container className={classes.cards} spacing={3}>
        {menuCard.map((card, i) => {
          const Icon = card.icon;
          return (
            <Grid item key={i} center md={3} sm={3} xs={5}>
              <Card {...rest} className={clsx(classes.root, className)}>
                <CardContent className={classes.content}>
                  <div className={classes.cardBody}>
                    <IconButton size="medium" color="primary">
                      <Icon />
                    </IconButton>

                    <Typography variant="h5">{card.cardName}</Typography>
                  </div>

                  <div className={classes.actions}>
                    <Button color="primary" size="small" variant="outlined">
                      <Link
                        // color="textPrimary"
                        component={RouterLink}
                        // noWrap
                        to={card.link}
                        variant="h5"
                      >
                        {t('overview.view')}
                      </Link>
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

MenuCardList.propTypes = {
  className: PropTypes.string
};

export default MenuCardList;
