import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import Statistics from './Statistics';
// import Notifications from './Notifications';
import MenuCardList from './MenuCardList';
// import Todos from './Todos';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  },
  statistics: {
    alignItems: 'center',

    marginTop: theme.spacing(3)
  },
  notifications: {
    marginTop: theme.spacing(6)
  },
  menu: {
    marginTop: theme.spacing(3)
  },
  todos: {
    marginTop: theme.spacing(6)
  }
}));

function Overview() {
  const classes = useStyles();

  const session = useSelector(state => state.auth.user);


  return (
    <Page className={classes.root} title="Overview">
      <Container className={classes.container}>
        <Header session={session} />
        <Statistics session={session} className={classes.statistics} />
        {/* <Notifications className={classes.notifications} /> */}
        <MenuCardList className={classes.menu} />
        {/* <Todos className={classes.todos} /> */}
      </Container>
    </Page>
  );
}

export default Overview;
