import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import errorReducer from './errorReducer';
import authReducer from './authReducer';

const rootReducer = combineReducers({
  // session: sessionReducer,
  error: errorReducer,
  auth: authReducer
});

export default rootReducer;
