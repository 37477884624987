import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const primary = '#aad0d9';
const secondary = '#aad0d9';
const tertiary = '#7d7d7d';
const black = '#000';

export default {
  primary: {
    contrastText: white,
    // dark: colors.indigo[900],
    main: primary,
    // light: colors.indigo[100]
  },
  secondary: {
    contrastText: white,
    // dark: colors.blue[900],
    main: secondary,
    // light: colors.blue.A400
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: black,
    // secondary: colors.blueGrey[600],
    link: tertiary
  },
  tertiary,
  white,
  grey: {
    default: colors.grey[200],
  },
  link: tertiary,
  icon: tertiary,
  background: {
    default: white,
    paper: white,
    navbar: primary
  },
  divider: colors.grey[200]
};
