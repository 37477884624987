/* eslint-disable no-confusing-arrow */
import { useSelector, useDispatch } from 'react-redux';
import { URL } from '../../global'
import { makeStyles } from '@material-ui/styles';
import { Snackbar, TextField, Button, Box, CircularProgress } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import validate from 'validate.js';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from 'axios';
import { returnErrors } from '../../actions/errorActions';
// import axios from 'src/utils/axios';
import Header from './Header';
//import { VerificationCodeRequest, VerificationCodeRequestCallback } from './API'
import { clearErrors } from 'src/actions/errorActions';
import CodeModal from './CodeModal'
import SetNewPassword from './SetNewPasswordModal';
import { create } from "jss";
import rtl from "jss-rtl";
import {
    StylesProvider,
    jssPreset,
    ThemeProvider,
    createTheme
} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(theme => ({
    root: {},
    fields: {
        marginTop: theme.spacing(2),
        width: '100%'
    },
    submitButton: {
        marginTop: theme.spacing(2),
        width: '100%'
    },
    buttonOutlined: {
        marginTop: theme.spacing(1),
        fontSize: '10px',
        width: '50%',
    },
    centerBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: "center"
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    },
    alert: {
        "& .MuiAlert-icon": {
            marginLeft: 10,
            marginRight: 10
        },
        "& .MuiAlert-action": {
            marginLeft: 10,
            marginRight: 10
        }

    },
}));


function ForgetPasswordForm({ className, ...rest }) {

    const dispatch = useDispatch();
    const classes = useStyles();
    const error = useSelector(state => state.error);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [openVerificationCodeModal, setOpenVerificationCodeModal] = useState(false);
    const [openSetNewPasswordModal, setOpenSetNewPasswordModal] = useState(false);

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });
    const { t } = useTranslation();
    // Configure JSS
    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

    const [alert, setAlert] = useState({});
    const Alert = props => <MuiAlert elevation={6} variant="filled" {...props} />;


    const handleVerificationCodeModalClose = () => {
        setOpenVerificationCodeModal(false);
    };
    const handleOpenSetNewPasswordModalClose = () => {
        setOpenSetNewPasswordModal(false);
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlert({ type: '', status: '', open: false });
    };

    const handelCodeRequest = values => {
        VerificationCodeRequestCallback(values);

    };
    const handelSetNewPasswordRequest = values => {
        SetNewPasswordRequestCallback(values);

    };

    const schemas = {
        email: {
            presence: {
                allowEmpty: false,
                message:
                    function (value) {
                        return validate.format("^%{num} " + t('login_page.empty_email'), {
                            num: value
                        });
                    }
            },
            format: {
                pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                message: function (value) {
                    return validate.format("^%{num} " + t('login_page.wrong_email'), {
                        num: value
                    });
                }
            },
        },
    };

    const handleChange = event => {
        event.persist();

        setFormState(prevFormState => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...prevFormState.touched,
                [event.target.name]: true
            }
        }));
    };
    const hasError = field =>
        !!(formState.touched[field] && formState.errors[field]);

    useEffect(() => {
        const errors = validate(formState.values, schemas);

        setFormState(prevFormState => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {}
        }));
    }, [formState.values]);

    const handleSubmitEmail = async event => {
        event.preventDefault();
        setIsLoading(true);

        // Clear perv errors on state
        dispatch(clearErrors());
        const email = formState.values.email

        console.log(email)
        dispatch(VerificationCodeRequest(email));
        setIsLoading(false);

    }

    useEffect(() => {
        if (error.status) {
            setIsLoading(false);
            setIsError(true);
            setTimeout(() => {
                setIsError(false);
            }, 2000);
        } else {
            // console.log(error);
            setIsLoading(false);

        }
        return () => {
            setIsLoading(false);
            setIsError(false);
            // console.log(error);
            // console.log(isLoading);
        };
    }, [error, setIsLoading, setIsError]);

    //APIs
    const VerificationCodeRequest = (email) => dispatch => {
        console.log("email: " + email)

        axios.get(`${URL}/api/User/forgetPassword`, {
            params: {
                userEmail: email
            }
        })
            .then(
                res => {
                    try {
                        var usernameText = email
                        console.log("Email address: " + usernameText)
                        var message = res.data.message
                        console.log("Request response: " + message)

                        localStorage.setItem('email', usernameText)
                        console.log("Email has been saved in the AsyncStorage. Email:" + usernameText)
                        var status = true
                        localStorage.setItem('status', status)
                        setOpenVerificationCodeModal(true);

                    } catch {
                        console.log("An error occurred while trying to process reset password request!")

                        setAlert({ type: 'error', status: t('errors.unexpected_error'), open: true })

                    }
                },
                err => {
                    var status = false
                    localStorage.setItem('status', status)
                    console.log("EEEERRRRR")

                    setAlert({ type: 'error', status: t('errors.login_404'), open: true })

                    setOpenVerificationCodeModal(false);


                }
            )
    }

    const VerificationCodeRequestCallback = (values) => {


        const email = localStorage.getItem('email')
        console.log("Email: " + email)
        console.log(values.code);

        const data = {
            code: values.code,
        };
        console.log('data: ' + JSON.stringify(data.code));



        axios.get(`${URL}/api/User/resetPasswordToken`, {
            params: {
                otpKey: values.code
            }
        })
            .then(
                res => {
                    try {

                        var resetPasswordToken = res.data.resetPasswordToken
                        console.log("Reset password token: " + resetPasswordToken)

                        localStorage.setItem('resetPasswordToken', resetPasswordToken)
                        console.log("resetPasswordToken has been saved in the AsyncStorage")
                        localStorage.setItem('success', 'success')
                        setOpenVerificationCodeModal(false);
                        setOpenSetNewPasswordModal(true)

                    } catch {
                        console.log("Catch an error occurred while trying to process validation code request!")

                        setAlert({ type: 'error', status: t('errors.unexpected_error'), open: true });

                    }
                },
                err => {
                    console.log("An error occurred while trying to process validation code request!")

                    setAlert({ type: 'error', status: t('errors.wrong_verification_code'), open: true });


                }
            )
    };

    const SetNewPasswordRequestCallback = (values) => {
        var email = localStorage.getItem('email')
        console.log("Email: " + email)
        console.log("password: " + values.password)
        console.log("password: " + values.confirm_new_password)
        const delay = ms => new Promise(res => setTimeout(res, ms));


        try {
            var resetPasswordToken = localStorage.getItem('resetPasswordToken')
            console.log("Reset password token: " + resetPasswordToken)
        } catch {
            console.log("An error occurred while trying to get email, and reset password token.!")
        }

        if (values.password && values.confirm_new_password) {
            const req = {
                "email": email,
                "restPasswordToken": resetPasswordToken,
                "newPassword": values.password,
                "confirmNewPassword": values.confirm_new_password
            }

            var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            let isValid = strongRegex.test(values.confirm_new_password)

            if (!isValid) {
                setAlert({ type: 'error', status: t('login_page.wrong_password'), open: true });

            } else if (values.password != values.confirm_new_password) {
                setAlert({ type: 'error', status: t('login_page.password_not_match'), open: true });

            } else {
                axios.put(`${URL}/api/User/resetPassword`,
                    req)
                    .then(
                        async res => {
                            try {
                                var code = res.status
                                console.log(code)
                            } catch {
                                console.log("An error occur regarding reset password response.!")
                            }

                            setAlert({ type: 'success', status: t('messages.change_password'), open: true })
                            await delay(3000);


                            localStorage.removeItem('email')
                            localStorage.removeItem('resetPasswordToken')
                            //console.log("Email, and reset password token have been removed from AsyncStorage"),

                            setOpenSetNewPasswordModal(false)
                            window.location.href = "/auth/login"


                            //this.props.navigation.navigate('login')
                        },
                        err => {

                            setAlert({ type: 'error', status: t('errors.unexpected_error'), open: true });

                        }
                    )
            }
        }
        else {
            setAlert({ type: 'error', status: t('errors.unexpected_error'), open: true });
        }
    }





    return (
        <form
            {...rest}
            className={clsx(classes.root, className)}
            onSubmit={handleSubmitEmail}
        >

            <Header />

            <StylesProvider jss={jss}>
                <ThemeProvider theme={document.body.dir === 'rtl' ? { direction: "rtl" } : { direction: "ltr" }}>
                    <Box
                        component="span"
                        m={1}
                        className={classes.centerBox}
                    >
                        <div className={classes.fields}>

                            <TextField
                                error={hasError('email')}
                                fullWidth
                                helperText={hasError('email') ? formState.errors.email[0] : null}
                                label={t('employees.email')}
                                name="email"
                                onChange={handleChange}
                                value={formState.values.email || ''}
                                variant="outlined"
                            />

                        </div>
                        <Button
                            className={classes.submitButton}
                            color="secondary"
                            disabled={!formState.isValid || isError}
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            {t('common.submit')}
                        </Button>
                    </Box>
                </ThemeProvider>
            </StylesProvider>


            {isLoading && (
                <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            {isError && (
                <div>
                    <Alert severity="error">{error.msg}</Alert>
                </div>
            )}




            <div>
                <CodeModal
                    onClose={handleVerificationCodeModalClose}
                    open={openVerificationCodeModal}
                    VerificationCodeRequestCallback={handelCodeRequest}
                />
            </div>
            <div>
                <SetNewPassword
                    onClose={handleOpenSetNewPasswordModalClose}
                    open={openSetNewPasswordModal}
                    SetNewPasswordRequestCallback={handelSetNewPasswordRequest}
                />
            </div>
            {alert.type && (
                <Snackbar
                    open={alert.open}
                    autoHideDuration={5000}
                    onClose={handleClose}
                >
                    {alert.type === 'success' ? (
                        <Alert className={classes.alert} onClose={handleClose} severity={alert.type}>
                            {`${alert.status}`}
                        </Alert>
                    ) : (
                        <Alert className={classes.alert} onClose={handleClose} severity={alert.type}>
                            {`${alert.status}`}
                        </Alert>
                    )}
                </Snackbar>
            )}

        </form>


    );
}
ForgetPasswordForm.propTypes = {
    className: PropTypes.string,
    VerificationCodeRequestCallback: PropTypes.func
};

export default ForgetPasswordForm;
