import React, { useState, useEffect } from 'react';
import { URL } from '../../global'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid, colors } from '@material-ui/core';
import axios from 'axios';
import Label from 'src/components/Label';
import { useTranslation } from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  overline: {
    marginTop: theme.spacing(1)
  },
  progress: {
    color: '#aad0d9',
  },
}));





function Statistics({ className, session, ...rest }) {
  //   const clickHandle = event => {
  //     event.stopImmediatePropagation()
  //     window.location.reload()
  // }
  const [newTotalEmployees, setNewTotalEmployees] = useState(0);
  const [newTotalLocations, setNewTotalLocations] = useState(0);
  const [employeesLoading, setEmployeesLoading] = useState(false);
  const [locationsLoading, setLocationsLoading] = useState(false);


  const token = localStorage.getItem('token');

  useEffect(() => {
    console.log(token);
    setEmployeesLoading(true);
    setLocationsLoading(true)

    const header = { Authorization: `Bearer ${token}` };

    const params = {
      pageNumber: 1,
      pageSize: 100000
    };

    const fetchLocations = axios.get(`${URL}/api/Locations`, {
      headers: header,
      params: params
    });

    const fetchEmployees = axios.get(`${URL}/api/Employee`, {
      headers: header,
      params: params
    });

    Promise.all([fetchLocations, fetchEmployees]).then(responses => {
      return Promise.all(
        responses.map(response => {
          if (response.status === 200) {
            return response.data
          } else {
            throw response;
          }
        })
      )
        .then(data => {
          setLocationsLoading(false);
          console.log(data[0].items.length);
          setNewTotalLocations(data[0].items.length)

          setEmployeesLoading(false);
          console.log(data[1].items.length);
          setNewTotalEmployees(data[1].items.filter(a => a.isAcitve == true).length)
        })
        .catch(err => {
          console.log(err);
        });
    });

    var totEmployees = newTotalEmployees
    console.log("totEmployees: " + totEmployees)
    var totLocations = newTotalLocations
    console.log("totLocations: " + totLocations)


  }, []);

  const classes = useStyles();

  const totalEmployees =
    session.numberOfEmployees > 0 ? session.numberOfEmployees : 0;
  const totalLocations =
    session.totalLocations > 0 ? session.totalLocations : 0;
  const { t } = useTranslation();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justify="space-between">
        {employeesLoading === true ?
          <Grid className={classes.item} item md={6} sm={6} xs={12}>
            <CircularProgress color="inherit" className={classes.progress} />
          </Grid>
          :
          <Grid className={classes.item} item md={6} sm={6} xs={12}>
            <Typography variant="h3">{newTotalEmployees}</Typography>
            <Typography className={classes.overline} variant="overline">
              {t('overview.total_employees')}
            </Typography>
          </Grid>
        }

        {locationsLoading === true ?
          <Grid className={classes.item} item md={6} sm={6} xs={12}>
            <CircularProgress color="inherit" className={classes.progress} />
          </Grid>
          :
          <Grid className={classes.item} item md={6} sm={6} xs={12}>
            <Typography variant="h3">{newTotalLocations}</Typography>
            <Typography className={classes.overline} variant="overline">
              {t('overview.locations')}
            </Typography>
          </Grid>
        }

        {/* <Grid
          className={classes.item}
          item
          md={3}
          sm={6}
          xs={12}
        >
          <div className={classes.titleWrapper}>
            <Typography
              component="span"
              variant="h2"
            >
              {statistics.watching}
            </Typography>
            <Label
              className={classes.label}
              color={colors.green[600]}
            >
              Live
            </Label>
          </div>
          <Typography
            className={classes.overline}
            variant="overline"
          >
            Watching now
          </Typography>
        </Grid> */}
      </Grid>
    </Card>
  );
}

Statistics.propTypes = {
  className: PropTypes.string,
  session: PropTypes.object
};

export default Statistics;
