import axios from 'axios';
import { returnErrors } from './errorActions';
import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL
} from './types';
import { URL } from '../global'

// Check token & load user
export const loadUser = () => (dispatch, getState) => {
  const token = getState().auth.token;

  if (!token) return;

  const header = { Authorization: `Bearer ${token}` };

  const fetchProfile = fetch(`${URL}/api/User/profile`, {
    headers: header
  });

  const fetchLocations = fetch(`${URL}/api/Locations`, {
    headers: header
  });

  // User loading
  dispatch({ type: USER_LOADING });

  Promise.all([fetchProfile, fetchLocations]).then(responses => {
    return Promise.all(
      responses.map(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw response;
        }
      })
    )
      .then(data => {
        // console.log(data);
        const profile = {
          ...data[0],
          totalLocations: data[1].length
          // totalEmployees: data[1].length
        };
        dispatch({
          type: USER_LOADED,
          payload: profile
        });
      })
      .catch(err => {
        // dispatch(returnErrors());
        dispatch({
          type: AUTH_ERROR
        });
      });
  });

  //   Promise.all(urls.map(url => fetch(url, {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   })
  //   .then(response => {
  //     // console.log(response);
  //     const res = response.json();
  //     if (response.status === 200) {
  //       return res;
  //     } else {
  //       throw res;
  //     }
  //     // return res;
  //   })
  //   .then(data => {
  //    return data;
  //     // dispatch({
  //     //   type: USER_LOADED,
  //     //   payload: data
  //     // });
  //   }).then(data => {
  //     console.log([...data]);
  //        dispatch({
  //          type: USER_LOADED,
  //          payload: data
  //        });
  //   })))
  //   .catch(err => {
  //     // dispatch(returnErrors());
  //     dispatch({
  //       type: AUTH_ERROR
  //     });
  //   });
};

// Login User
export const login = ({ email, password }) => dispatch => {

  // Headers
  // console.log({ email, password });
  const config = {
    // useCredentails: true,
    // mode: 'no-cors', // no-cors, *cors, same-origin
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // Request body
  const body = JSON.stringify({ email, password });

  fetch(`${URL}/api/User/token`, {
    method: 'POST',
    // mode: 'cors',
    headers: {
      // Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body
  })
    .then(response => {
      const res = response.json();
      if (response.status === 200) {
        return res;
      } else if (response.status === 404) {
        // console.log({ response });
        dispatch(
          returnErrors("errors.login_404", 404, 'LOGIN_FAIL')
        );
        dispatch({
          type: LOGIN_FAIL
        });
        // return res;
      } else if (response.status === 400) {
        // console.log({ response });
        dispatch(
          returnErrors('errors.login_400', 400, 'LOGIN_FAIL')
        );
        dispatch({
          type: LOGIN_FAIL
        });
        // return res;
      } else {
        throw { msg: 'errors.unexpected_error', status: response.status };
      }

      // return res;
    })
    .then(data => {
      if (data) {
        // dispatch(loadUser());

        // console.log(dispatch(loadUser()));
        dispatch({
          type: LOGIN_SUCCESS,
          payload: data
        });
      }
      // }
      // console.log(data);
    })
    .catch(({ msg = 'errors.unexpected_error', status = 400 }) => {
      dispatch(returnErrors(msg, status, 'LOGIN_FAIL'));
      dispatch({
        type: LOGIN_FAIL
      });
    });
};

// Logout User
export const logout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};
