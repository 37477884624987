import uuid from 'uuid/v1';
import moment from 'moment';
import mock from 'src/utils/mock';

let employees = [
  {
    id: 1,
    name: 'Ekaterina Tankova',
    email: 'ekaterina.tankova@devias.io',
    phone: '+966512345678',
    city: 'Riyadh',
    group: 'Group1',
    avatar: '/images/avatars/avatar_2.png'
  },
  {
    id: 2,
    name: 'Cao Yu',
    email: 'cao.yu@devias.io',
    phone: '+966512345678',
    city: 'Riyadh',
    group: 'Group1',
    avatar: '/images/avatars/avatar_3.png'
  },
  {
    id: 3,
    name: 'Alexa Richardson',
    email: 'alexa.richardson@devias.io',
    phone: '+966512345678',
    city: 'Zulfi',
    group: 'Group2',
    avatar: '/images/avatars/avatar_4.png'
  },
  {
    id: 17,
    name: 'Anje Keizer',
    email: 'anje.keizer@devias.io',
    phone: '+966512345678',
    city: 'Hail',
    group: 'Group2'
  },
  {
    id: 4,
    name: 'Anje Keizer',
    email: 'anje.keizer@devias.io',
    phone: '+966512345678',
    city: 'Hail',
    group: 'Group2'
  },
  {
    id: 5,
    name: 'Anje Keizer',
    email: 'anje.keizer@devias.io',
    phone: '+966512345678',
    city: 'Hail',
    group: 'Group2'
  },
  {
    id: 6,
    name: 'Anje Keizer',
    email: 'anje.keizer@devias.io',
    phone: '+966512345678',
    city: 'Hail',
    group: 'Group2'
  },
  {
    id: 7,
    name: 'Cao Yu',
    email: 'cao.yu@devias.io',
    phone: '+966512345678',
    city: 'Riyadh',
    group: 'Group1',
    avatar: '/images/avatars/avatar_3.png'
  },
  {
    id: 8,
    name: 'Cao Yu',
    email: 'cao.yu@devias.io',
    phone: '+966512345678',
    city: 'Riyadh',
    group: 'Group1',
    avatar: '/images/avatars/avatar_3.png'
  },
  {
    id: 9,
    name: 'Cao Yu',
    email: 'cao.yu@devias.io',
    phone: '+966512345678',
    city: 'Riyadh',
    group: 'Group1',
    avatar: '/images/avatars/avatar_3.png'
  },
  {
    id: 10,
    name: 'Cao Yu',
    email: 'cao.yu@devias.io',
    phone: '+966512345678',
    city: 'Riyadh',
    group: 'Group1',
    avatar: '/images/avatars/avatar_3.png'
  },
  {
    id: 11,
    name: 'Cao Yu',
    email: 'cao.yu@devias.io',
    phone: '+966512345678',
    city: 'Riyadh',
    group: 'Group1',
    avatar: '/images/avatars/avatar_3.png'
  },
  {
    id: 12,
    name: 'Cao Yu',
    email: 'cao.yu@devias.io',
    phone: '+966512345678',
    city: 'Riyadh',
    group: 'Group1',
    avatar: '/images/avatars/avatar_3.png'
  }
];

const updateEmployees = id => {
  console.log(id);
  employees = employees.splice(emp => emp.id, 1);
};

mock.onDelete('/api/management/customers/1').reply(config => {
  // updateEmployees(1);
  employees = employees.filter(emp => emp.id !== 1);
  return [
    200,
    { message: 'Employee 1 has been deleted', customers: employees }
  ];
});
mock.onDelete('/api/management/customers/4').reply(config => {
  // updateEmployees(1);
  employees = employees.filter(emp => emp.id !== 4);
  return [
    200,
    { message: 'Employee 4 has been deleted', customers: employees }
  ];
});

mock.onGet('/api/management/customers').reply(200, {
  customers: employees
});

mock.onGet('/api/management/customers/1').reply(200, {
  customer: employees.filter(emp => emp.id === 1)
});
mock.onGet('/api/management/customers/2').reply(200, {
  customer: employees.filter(emp => emp.id === 2)
});

mock.onGet('/api/management/customers/3').reply(200, {
  customer: employees.filter(emp => emp.id === 3)
});

mock.onPut('/api/management/customers/3').reply(config => {
  // updateEmployees(1);
  employees = employees.filter(emp => emp.id !== 4);
  return [
    200,
    { message: 'Employee 3 has been Updated', customers: employees }
  ];
});

mock.onGet('/api/management/customers/1/summary').reply(200, {
  summary: {
    name: 'Ekaterina Tankova',
    email: 'ekaterina@devias.io',
    phone: '+55 748 327 439',
    state: 'Alabama',
    country: 'United States',
    zipCode: '240355',
    address1: 'Street John Wick, no. 7',
    address2: 'House #25',
    iban: '4142 **** **** **** ****',
    autoCC: false,
    verified: true,
    currency: '$',
    invoices: [
      {
        id: uuid(),
        type: 'paid',
        value: 10.0
      },
      {
        id: uuid(),
        type: 'paid',
        value: 15.0
      },
      {
        id: uuid(),
        type: 'due',
        value: 5
      },
      {
        id: uuid(),
        type: 'income',
        value: 10.0
      }
    ],
    vat: 19,
    balance: 0,
    emails: [
      {
        id: uuid(),
        description: 'Order confirmation',
        created_at: moment()
          .subtract(3, 'days')
          .subtract(5, 'hours')
          .subtract(34, 'minutes')
      },
      {
        id: uuid(),
        description: 'Order confirmation',
        created_at: moment()
          .subtract(4, 'days')
          .subtract(11, 'hours')
          .subtract(49, 'minutes')
      }
    ]
  }
});

mock.onGet('/api/management/customers/1/invoices').reply(200, {
  invoices: [
    {
      id: uuid(),
      date: moment(),
      description: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
      paymentMethod: 'Credit Card',
      value: '5.25',
      currency: '$',
      status: 'paid'
    },
    {
      id: uuid(),
      date: moment(),
      description: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
      paymentMethod: 'Credit Card',
      value: '5.25',
      currency: '$',
      status: 'paid'
    }
  ]
});

mock.onGet('/api/management/customers/1/logs').reply(200, {
  logs: [
    {
      id: uuid(),
      status: 200,
      method: 'POST',
      route: '/api/purchase',
      desc: 'Purchase',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(2, 'minutes')
        .subtract(56, 'seconds')
    },
    {
      id: uuid(),
      status: 522,
      error: 'Invalid credit card',
      method: 'POST',
      route: '/api/purchase',
      desc: 'Purchase',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(2, 'minutes')
        .subtract(56, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'DELETE',
      route: '/api/products/d65654e/remove',
      desc: 'Cart remove',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(8, 'minutes')
        .subtract(23, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'GET',
      route: '/api/products/d65654e/add',
      desc: 'Cart add',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(20, 'minutes')
        .subtract(54, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'GET',
      route: '/api/products/c85727f/add',
      desc: 'Cart add',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(34, 'minutes')
        .subtract(16, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'GET',
      route: '/api/products/c85727f',
      desc: 'View product',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(54, 'minutes')
        .subtract(30, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'GET',
      route: '/api/products',
      desc: 'Get products',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(56, 'minutes')
        .subtract(40, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'POST',
      route: '/api/login',
      desc: 'Login',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(57, 'minutes')
        .subtract(5, 'seconds')
    }
  ]
});
